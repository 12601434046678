var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper/bundle";
class src_default extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, __spreadValues(__spreadValues({}, this.defaultOptions), this.optionsValue));
  }
  disconnect() {
    this.swiper.destroy();
    this.swiper = void 0;
  }
  get defaultOptions() {
    return {};
  }
}
src_default.values = {
  options: Object
};
export { src_default as default };
